body {
    color: rgb(51, 51, 51);
    font-weight: 300;
}

.page-content {
    position: relative;
}

@media only screen and (hover: none) and (pointer: coarse) and (orientation:landscape) and (max-width: 760px) {
    .janus-biz-section {
        font-size: 10px;
    }

    .skills-grid {
        font-size: 16px !important;
        width: 80%;
        margin-top: 2rem;
        grid-template-columns: repeat(auto-fit, minmax(max(200px, 30vw), 1fr)) !important;
    }

    .janus-biz-contact-info {
        font-size: 16px;
    }

    .company-address {
        font-size: 16px;
    }

    .janus-biz-footer {
        font-size: 16px;
    }
}

@media screen and (orientation:landscape) and (max-width: 1019px) {
    .janus-biz-single-grid-element {
        justify-items: left !important
    }
}

.janus-biz-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 3rem;
    border-bottom: 1px solid rgba(0, 0, 0, .1)
}

.janus-biz-section>h1 {
    //margin: 2rem 2rem 0 2rem;
    text-align: center;
    font-weight: 500;
    width: 100%;
    height: 100px;
    text-align: center;
    vertical-align: middle;
    line-height: 100px;
    background-color: #dbdbdb;
}

.janus-biz-section>p {
    margin-top: 2rem;
    width: 80%;
    justify-content: center;
}

.janus-biz-section:nth-child(even) {
    background-color: #f9fafc;
}

.skills-grid {
    width: 80%;
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(max(400px, 30vw), 1fr));
    column-gap: 1rem;
    row-gap: 2rem;
}

.janus-biz-rounded-icon {
    // border: 2px solid #0092d7;
    // border-radius: 100% !important;
    // color: #0092d7;
    background: 0 0;
    display: table;
}

.janus-biz-rounded-icon>img {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.janus-biz-single-grid-element {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: min-content;
    align-items: stretch;
    text-align: left;
}

@media screen and (max-device-width: 640px) {
    .janus-biz-single-grid-element {
        justify-items: left;
    }

    .janus-biz-single-grid-element>p {
        max-width: 240px;
    }
}

.janus-biz-single-grid-element>div {
    grid-area: 1 / 1 / 3 / 2;
    margin-right: 1rem;
}

.janus-biz-single-grid-element>h3 {
    margin-top: auto;
}

.janus-biz-divider {
    width: 40vw;
    display: inline-block;
    position: absolute;
    top: -2px;
    border: 2px solid #0093d7d5;
    border-radius: 5px;
}

.janus-biz-divider.left {
    left: 1vw;
    box-shadow: -5px 1px 10px 1px #606060;
}

.janus-biz-divider.right {
    right: 1vw;
    box-shadow: 5px 1px 10px 1px #606060;
}

.janus-biz-divider-icon {
    position: absolute;
    transform: translateY(-50%);
    margin: auto;
    left: 0;
    right: 0;
    height: 48px;
    width: 48px;
}

.janus-biz-info-window-content {
    text-align: center;
}

@media screen and (max-width: 640px) {
    .janus-biz-contact-map>div {
        width: 350px !important;
    }

    .janus-biz-single-grid-element {
        justify-items: left;
    }

    .janus-biz-transport-img {
        width: 40vh !important;
        transition: width 1s;
    }
}

@media screen and (max-width: 820px) and (min-width: 640px) {
    .janus-biz-contact-map>div {
        width: 500px !important;
    }

    .janus-biz-single-grid-element {
        justify-items: left;
    }

    .janus-biz-transport-img {
        width: 60vh !important;
        transition: width 1s;
    }
}

.janus-biz-quality {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.janus-biz-quality .gmp-logo {
    height: 150px;
}

.janus-biz-quality .gmp-certificate {
    margin-top: 2rem;
    height: 60vh;
}

.janus-biz-transport-img {
    margin-top: 2rem;
    height: 100%;
    width: 77vh;
}

.janus-biz-quality .gmp-certificate-embed {
    margin-top: 2rem;
    height: 420px;
    background: #0092d7;
    background-color: white;
}

.janus-biz-quality .gmp-text {
    margin-top: 8px;
    background-color: #009a3e;
    color: #FFF;
    padding: 2px;
    font-weight: 500;
    border: 3px solid #aed6af;
    border-radius: 10px;
    text-align: center;
    margin-top: 8px;
    width: 100%;
}

.janus-biz-regulation>ul {
    list-style-position: inside;
    padding-top: 1rem;
}

.janus-biz-regulation>ul>li {
    padding-top: 1rem;
    line-height: 1.2;
    list-style-position: outside
}

.janus-biz-bold {
    font-weight: 400;
}

.janus-biz-operate {
    text-align: center;
}

.janus-biz-contact-map {
    margin-top: 3rem;
}

.janus-biz-contact-map>div {
    width: 640px;
    height: 400px;
}

.janus-biz-contact-info {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 2rem;
}

.company-address {
    margin-top: 2rem;
    text-align: center;
}

.company-address>p {
    margin-top: 5px;
    margin-bottom: 10px;
    text-align: center;
}

.janus-biz-contact-info {
    margin-top: 2rem;
}

.janus-biz-contact-info .company-contact .contact-element {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    margin-bottom: 10px;
}

.janus-biz-contact-info .company-info {
    margin-top: -2px
}

.janus-biz-contact-info .company-info>p {
    margin-bottom: 10px;
}

.janus-biz-link {
    text-decoration: none;
    color: #0092d7;
}

.janus-biz-footer {
    display: flex;
    text-align: center;
    height: 150px;
}

.janus-biz-link.home {
    cursor: pointer;
    left: 5px;
    padding-right: 10px;
}

.janus-biz-products {
    width: 80%;
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(max(400px, 30vw), 1fr));
    column-gap: 1rem;
    row-gap: 2rem;
}

.janus-biz-products .product {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media only screen and (hover: none) and (pointer: coarse) and (max-width: 480px) {
    .janus-biz-products {
        font-size: 16px !important;
        width: 80%;
        margin-top: 2rem;
        grid-template-columns: repeat(auto-fit, minmax(max(200px, 30vw), 1fr)) !important;
    }
    .janus-biz-products .photo {
        width: 80vw;
        height: revert !important;
    }
}

.janus-biz-products .description {
    margin-top: 1rem;
}

.janus-biz-products .header {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.janus-biz-products .photo {
    height: 45vh;
    box-shadow: 0px 0px 20px 0px;
}