* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "SF Pro Display", "SF Pro Icons", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

body {
  margin: 0;
  text-shadow: 0 0 1px rgba(0, 0, 0, .1);
  overflow-x: hidden;
  position: relative;
  line-height: 1.2;
}

html {
  overflow-x: hidden;
}

.CookieConsent {
  border-radius: 5px;
  padding: 20px 30px;
  width: 95%;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  font-size: 10px;
  background-color: #333333ee !important;
}

.cookie-accept {
  background-color: #0092d7 !important;
  color: white !important;
  text-shadow: 0 0 1px rgba(0, 0, 0, .1);
}

.cookie-decline {
  background-color: #4e6710 !important;
  color: white !important;
  text-shadow: 0 0 1px rgba(0, 0, 0, .1);
}

.janus-biz-flags-chooser>ul {
  display: inline !important;
}

.janus-biz-flags-chooser>button {
  border-color: transparent !important;
}

.janus-biz-flags-chooser>button::after {
  border-bottom-color: white !important;
  border-top-color: white !important;
}

.janus-biz-flags-chooser>ul {
  background-color: #efefef;
  border-color: transparent;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}

.janus-biz-flags-chooser>ul>li>span>span>svg {
  filter: drop-shadow(0px 3px 10px rgb(0 0 0 / 0.5));
}

.janus-biz-flags-chooser>ul>li:hover {
  background-color: inherit;
}